class UtilAccount {
    isValidMail(mail: string) {
        // last 4 chars es: '.com', '*.it'
        let domain: any = mail.split('@')
        if (domain.length != 2) {
            return false
        }

        domain = domain[1]
        const extension: string[] = domain.split('.')

        return (
            domain.includes('.') &&
            extension.length >= 2 &&
            extension[extension.length - 1] != ''
        )
    }

    isValidPassword(pass: string) {
        const hasUppercase = /[A-Z]/.test(pass)
        const hasLowercase = /[a-z]/.test(pass)
        const hasNumber = /[0-9]/.test(pass)

        return pass.length >= 8 && hasUppercase && hasLowercase && hasNumber
    }
}

export const utilAccount = new UtilAccount()
